import { DashboardApi } from "api"
import { DataStatus } from "components"
import { ChartBarVertical } from "components2"
import { useState } from "react"
import { Card, Nav, Tab } from "react-bootstrap"
import { useQuery } from "react-query"
import { rupiahConvert } from "utilities2"

const rupiah = rupiahConvert();

export const TabPersediaanBarang = ({
  monthOptions,
  startMonth,
  endMonth,
  year,
}) => {
  const [selectedMonth, setSelectedMonth] = useState({
    label: "",
    value: startMonth,
  });

  const monthsInRange = monthOptions.filter(
    (val) => val.value >= startMonth && val.value <= endMonth
  );

  const getNilaiKelompok = useQuery(
    ["dashboard", "nilai-kelompok", startMonth, endMonth, year],
    () =>
      DashboardApi.get({
        modul : "INV",
        part : "summary",
        tahun: year,
        bulan_mulai: startMonth,
        bulan_selesai: endMonth,
      })
  );

  return (
    <Card className="border">
      {monthsInRange?.length ? (
        <Tab.Container
          key={monthsInRange.join("-")}
          defaultActiveKey={monthsInRange[0].value}
        >
          <Card.Header className="bg-white">
            <Nav variant="tabs" className="bg-white">
              {monthsInRange?.map((month) => (
                <Nav.Item className="bg-white">
                  <Nav.Link
                    className="py-1 px-2"
                    eventKey={month.value}
                    onClick={() =>
                      setSelectedMonth({
                        label: month.label,
                        value: month.value,
                      })
                    }
                  >
                    {month.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>

          <Card.Body>
            <Tab.Content>
              <div className="text-center">
                <b>
                  Nilai Persediaan Barang Bulan {selectedMonth?.label} {year}
                </b>
                <br />
                <b>Berdasarkan Kelompok Barang</b>
              </div>

              {getNilaiKelompok.isFetching ? (
                <div className="d-flex align-items-center justify-content-center my-5">
                  Memuat data . . .
                </div>
              ) : (
                <ChartBarVertical
                  showLegend={false}
                  data={{
                    labels:
                      getNilaiKelompok?.data?.data?.[selectedMonth?.value]?.map(
                        (val) => val?.nama_kelompok
                      ) ?? [],
                    datasets: [
                      {
                        data:
                          getNilaiKelompok?.data?.data?.[
                            selectedMonth?.value
                          ]?.map((val) => val?.nilai_persediaan) ?? [],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              )}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </Card>
  );
};
