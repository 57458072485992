import { DashboardApi } from 'api'
import { DatePicker } from 'components'
import { Button, ChartLine, Select } from 'components2'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { dateConvert, rupiahConvert } from 'utilities2'
import { InfoItem, TablePO, TabTotalPembelianPerHari } from './components'
import { monthOptions } from './constants'
import { range } from 'lodash'

const date = dateConvert();
const rupiah = rupiahConvert();

export const DashboardProcurement = ({ setNavbarTitle }) => {
  const monthNow = date.getMonth(new Date());
  const defaultEndDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate()
  );
  const defaultMonthName = convertDate(monthNow, date.getMonth(defaultEndDate));
  const [filter, setFilter] = useState({
    month: date.getMonth(new Date()),
    monthName: date.getDetailMonth(new Date()),
    year: date.getYear(new Date()),
    convertMonth: {
      startDate: defaultMonthName[0],
      endDate: defaultMonthName[1],
    },
  });

  const dateSelected = `${filter.year}-${filter.month}-01`;

  const getTotalVendor = useQuery(['dashboard', 'PRO', 'total_vendor'], () =>
    DashboardApi.get({
      modul: 'PRO',
      part: 'total_vendor',
    }),
  );
  const getVendorResmi = useQuery(
    ['dashboard', 'PRO', 'vendor_resmi'],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'vendor_resmi',
      }),
  );
  const getCalonVendor = useQuery(
    ['dashboard', 'PRO', 'calon_vendor'],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'calon_vendor',
      }),
  );
  const getVendorTerbaik = useQuery(
    ['dashboard', 'PRO', 'vendor_terbaik', dateSelected],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'vendor_terbaik',
        bulan: filter.month,
        tahun: filter.year,
      }),
  );
  const getTotalPembelian = useQuery(
    ['dashboard', 'PRO', 'total_pembelian', filter.month, filter.year],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'total_pembelian',
        bulan: filter.month,
        tahun: filter.year,
      }),
  );
  const getTotalPembelianTahun = useQuery(
    ['dashboard', 'PRO', 'po_tahunan', filter.year],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'po_tahunan',
        tahun: filter.year,
      }),
  );
  const getTotalPembelianHari = useQuery(
    ['dashboard', 'PRO', 'po_harian', filter.month, filter.year],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'po_harian',
        bulan: filter.month,
        tahun: filter.year,
      }),
  );

  useEffect(() => setNavbarTitle('Dashboard Procurement'), []);

  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          startMonth: new Date(),
          endMonth: defaultEndDate,
          year: filter.year,
        }}
        onSubmit={(val) => {
          const startMonth = date.getMonth(new Date(val.startMonth));
          const endMonth = date.getMonth(new Date(val.endMonth));
          const monthName = convertDate(startMonth, endMonth);

          setFilter({
            startMonth,
            endMonth,
            year: val.year,
            convertMonth: {
              startDate: monthName[0],
              endDate: monthName[1],
            },
          });
        }}
      >
        {({ values, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              {/* Bulan */}
              <div className="d-flex align-items-center mr-2">
                <div style={{ width: 150 }}>
                  <DatePicker
                    selectsRange
                    dateFormat="MMMM"
                    showMonthYearPicker
                    selected={values.startMonth}
                    startDate={values.startMonth}
                    maxDate={values.startMonth ? generateMaxDate(values) : null}
                    endDate={values.endMonth}
                    onChange={([startMonth, endMonth]) =>
                      setValues({
                        ...values,
                        startMonth: startMonth,
                        endMonth: endMonth,
                      })
                    }
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={yearOptions}
                    defaultValue={yearOptions.find(
                      (val) => val.value === values.year
                    )}
                    onChange={(val) =>
                      setValues((prev) => ({ ...prev, year: val.value }))
                    }
                  />
                </div>
              </div>

              {/* Button */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <Button
                  disabled={!values.startMonth || !values.endMonth}
                  text="Filter"
                  className="px-4"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="mt-3">
        {/* Info Total Vendor */}
        <Col className="mb-3">
          <InfoItem
            type="default"
            loading={getTotalVendor.isFetching}
            title="Total Vendor"
            percent={100}
            value={
              getTotalVendor.data?.data
                ? parseInt(getTotalVendor.data?.data)
                : '-'
            }
            data={[100]}
          />
        </Col>

        {/* Info Vendor Resmi */}
        <Col className="mb-3">
          <InfoItem
            loading={getVendorResmi.isFetching}
            title="Vendor Resmi"
            percent={
              getVendorResmi.data?.data?.vendor_resmi
                ? parseInt(getVendorResmi.data?.data?.vendor_resmi)
                : '-'
            }
            value={
              getVendorResmi.data?.data?.jumlah
                ? parseInt(getVendorResmi.data?.data?.jumlah)
                : '-'
            }
            data={[
              getVendorResmi.data?.data?.vendor_resmi
                ? parseInt(getVendorResmi.data?.data?.vendor_resmi)
                : '-',
              parseInt(100) -
                parseInt(
                  parseInt(getCalonVendor.data?.data?.calon_vendor) ?? 0
                ),
            ]}
          />
        </Col>

        {/* Info Calon Vendor */}
        <Col className="mb-3">
          <InfoItem
            loading={getCalonVendor.isFetching}
            title="Calon Vendor"
            percent={
              getCalonVendor.data?.data?.calon_vendor
                ? parseInt(getCalonVendor.data?.data?.calon_vendor)
                : '-'
            }
            value={
              getCalonVendor.data?.data?.jumlah
                ? parseInt(getCalonVendor.data?.data?.jumlah)
                : '-'
            }
            data={[
              getCalonVendor.data?.data?.calon_vendor
                ? parseInt(getCalonVendor.data?.data?.calon_vendor)
                : '-',
              parseInt(100) -
                parseInt(
                  parseInt(getCalonVendor.data?.data?.calon_vendor) ?? 0
                ),
            ]}
          />
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6}>
          {/* Vendor Terbaik Section */}
          <div className="p-3 bg-white border rounded shadow-sm mb-3 mt-2">
            <b>
              Vendor Terbaik {filter.monthName} {filter.year}
            </b>
            {getVendorTerbaik.isFetching ? (
              <div className="d-block text-center my-3">Memuat data . . .</div>
            ) : (
              <>
                <div className="d-flex align-items-center m-2">
                  <div
                    className="d-flex align-items-center justify-content-center mr-2 rounded"
                    style={{
                      minHeight: 25,
                      minWidth: 25,
                      fontSize: 14,
                      background: '#FFD700',
                    }}
                  >
                    1
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {getVendorTerbaik?.data?.data?.[0]?.nama_vendor ?? '-'}
                  </div>
                </div>
                <div className="d-flex align-items-center m-2">
                  <div
                    className="d-flex align-items-center justify-content-center mr-2 rounded"
                    style={{
                      minHeight: 25,
                      minWidth: 25,
                      fontSize: 14,
                      background: '#C0C0C0',
                    }}
                  >
                    2
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {getVendorTerbaik?.data?.data?.[1]?.nama_vendor ?? '-'}
                  </div>
                </div>
                <div className="d-flex align-items-center m-2">
                  <div
                    className="d-flex align-items-center justify-content-center mr-2 rounded"
                    style={{
                      minHeight: 25,
                      minWidth: 25,
                      fontSize: 14,
                      background: '#CD7F32',
                    }}
                  >
                    3
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {getVendorTerbaik?.data?.data?.[2]?.nama_vendor ?? '-'}
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Total Pembelian Section */}
          <div className="p-3 bg-white border rounded shadow-sm mb-3 mt-2">
            <b>
              Total Pembelian {filter.convertMonth?.startDate} -{' '}
              {filter.convertMonth?.endDate} {filter.year}
            </b>
            {getTotalPembelian.isFetching ? (
              <div className="d-block text-center my-3">Memuat data . . .</div>
            ) : (
              <>
                <div style={{ fontSize: 32 }}>
                  <b>
                    {getTotalPembelian.data?.data?.total_pembelian
                      ? rupiah.getWithComa(
                          getTotalPembelian.data?.data?.total_pembelian
                        )
                      : '-'}
                  </b>
                </div>
              </>
            )}
          </div>

          {/* Table Section */}
          <TablePO filter={filter} />
        </Col>

        <Col md={6}>
          {/* Chart Total Pembelian Bulan */}
          <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
            <div className="text-center">
              <b>Total Pembelian Per Bulan Tahun {filter.year}</b>
            </div>
            {getVendorTerbaik.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartLine
                  showLegend={false}
                  data={{
                    labels: monthOptions.map((val) => val.label),
                    datasets: [
                      {
                        data: monthOptions.map((val) => {
                          const findMonth =
                            getTotalPembelianTahun?.data?.data?.find(
                              (el) =>
                                el?.bulan_po?.toString() ===
                                val.value.toString()
                            );

                          return findMonth?.total
                            ? parseFloat(findMonth.total)
                            : 0;
                        }),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>

          {/* Chart Total Pembelian Hari*/}
          <TabTotalPembelianPerHari
            monthOptions={monthOptions}
            year={filter.year}
            startMonth={filter.startMonth}
            endMonth={filter.endMonth}
          />
        </Col>
      </Row>
    </>  );
};


function convertDate  (startDate, endDate) {
  const dateFilter = [startDate, endDate];

  const monthName = dateFilter.map((val) => {
    const monthValue = val; // Angka bulan (contoh: 10 untuk Oktober)
    const currentYear = new Date().getFullYear(); // Mendapatkan tahun saat ini
    const dayOfMonth = date.getDay(new Date()); // Contoh hari 1, Anda bisa menggantinya sesuai kebutuhan

    const todayDate = date.getDetailMonth(
      new Date(currentYear, monthValue - 1, dayOfMonth)
    );

    return todayDate;
  });

  return monthName;
};

 function generateMaxDate  (values)  {
  const date = new Date(values.startMonth);
  date.setMonth(date.getMonth() + 12);
  return date;
};

export const yearOptions = range(2000, parseInt(date.getYear(new Date()) + 1))
  .map((val) => ({
    label: val,
    value: val,
  }))
  .reverse();